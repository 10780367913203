import { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import nft1 from '../images/NFTRelated/nft1.png'
import nft2 from '../images/NFTRelated/nft2.png'
import nft3 from '../images/NFTRelated/nft3.png'
import nft4 from '../images/NFTRelated/nft4.png'
import nft5 from '../images/NFTRelated/nft5.png'
import { mock } from 'mockjs'
import 'dayjs/locale/en'
import avatar from '../images/common/avater.png'
import ethIcon from '../images/common/ethIcon.png'
import NFTCard from '../components/NFTCard'
import NFTAuthorCard from '../components/NFTAuthorCard'
import Popup from '../components/Popup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMoralis } from '../hooks/moralis'
import dayjs from 'dayjs'
import Toast from '../components/toast'
import { CleanValueIcon } from '../components/CleanValueIcon'
import { NFTInfoType } from '../hooks/type'
import { marketContract, nftContract } from '../hooks/config'
import SaleNFTCard from '../components/SaleNFTCard'

const NFTCreatorsDetails = () => {
  const {
    Moralis,
    getNFTsByContract,
    approveSell,
    getApproveSell,
    listNFTs,
    getSoldNFTforUser,
    getNFTsByUser,
    getAllNFTs,
    getSoldNFTsforUser,
    getSaleNFT,
  } = useMoralis()
  const [userInfo, setUserinfo] = useState<any>()
  const [currentToken, setCurrentToken] = useState<any>()
  const [owners, setOwners] = useState<number>(0)
  const [userAddress, setUserAddress] = useState<any>()
  const [ownedList, setOwnedList] = useState()
  const [saleList, setSaleList] = useState()
  const [soldList, setSoldList] = useState()
  const [activeTab, setActiveTab] = useState(0)
  const onTabChange = async (i: number) => {
    setActiveTab(i)
  }

  const [sellPopupVisible, setSellPopupVisible] = useState(false)
  const onToSell = (token_id: string, token_address: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const isApproved = '0x0000000000000000000000000000000000000000'
      getApproveSell?.(token_id, token_address).then((res: any) => {
        if (res === isApproved) {
          approveSell?.(token_id, token_address).then((res: any) => {
            if (res) {
              document.body.style.overflow = 'hidden'
              setCurrentToken({ token_id, token_address })
              setSellPopupVisible(true)
            }
          })
        } else {
          document.body.style.overflow = 'hidden'
          setCurrentToken({ token_id, token_address })
          setSellPopupVisible(true)
        }
      })
    }
  }

  const {
    register: sellRegister,
    reset: sellReset,
    handleSubmit: sellHandleSUbmit,
    formState: { errors: sellErrors },
    watch: sellWatch,
    resetField: sellResetField,
  } = useForm<{ sellPrice: string }>()
  const onToSellSubmit: SubmitHandler<{ sellPrice: string }> = data => {
    const { token_id, token_address } = currentToken
    listNFTs?.(token_id, token_address, data.sellPrice).then(res => {
      console.log(res)
      document.body.style.overflow = 'auto'
      setSellPopupVisible(false)
      sellReset()
    })
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const user = Moralis?.User.current()
      if (user) {
        setUserAddress(
          user.get('ethAddress') ?? window?.ethereum?.selectedAddress,
        )
        setUserinfo(user)
        getNFTsByContract?.({ address: marketContract }).then(
          async (res: any) => {
            const list = []
            if (res?.result.length > 0) {
              const promises = res?.result?.map((item: any) => {
                return getSaleNFT?.(item.token_id)
              })
              const responses = await Promise.allSettled(promises)
              responses.forEach((item, index) => {
                if (item.status === 'fulfilled') {
                  if (item.value.seller === user.get('ethAddress')) {
                    list.push(res.result[index])
                  }
                }
              })
            }
            setSaleList(list)
          },
        )
        getNFTsByContract?.({
          address: window?.ethereum?.selectedAddress,
        }).then((res: any) => {
          setOwnedList(res?.result)
        })
        getSoldNFTsforUser?.(
          window?.ethereum?.selectedAddress,
          nftContract,
        ).then(res => {
          setSoldList(res)
        })
      }
    }
  }, [])

  return (
    <>
      {/* TODO: New Header Component */}
      <Header className="!max-w-[1845px] xl:!max-w-[1470px] lg:!max-w-[1095px] xmd:!max-w-[720px] sm:!max-w-[350px]" />
      <Toast limit={1} />
      <div className="bg-[#F3F7FA]">
        <div
          className="min-h-[398px] bg-slate-500 h-full bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url(${
              [ownedList, saleList, soldList][activeTab]?.[0]?.metadata?.fileURI
            })`,
          }}
        >
          <div className="backdrop-blur-[6px] backdrop-saturate-[180%] bg-[rgba(255,255,255,0.71)] w-full min-h-[398px] pt-20">
            {/* User Content */}
            <div className="flex justify-between mx-auto mt-8 max-w-7xl md:px-16 sm:px-4">
              <div className="flex">
                <img
                  src={userInfo?.get('avatar')?._url ?? avatar}
                  alt="userAvatar"
                  className="w-[174px] h-[174px] border-[6px] border-white rounded-full"
                />
                <div className="flex flex-col justify-between py-6 ml-8">
                  <div className="font-boldText font-bold text-[36px] leading-[42px]">
                    {userInfo?.get('username') ?? 'anonymous'}
                  </div>
                  <div className="flex items-center text-[#4F4F4F]">
                    <img src={ethIcon} alt="ethIcon" className="w-4 h-4" />
                    <div className="ml-2">
                      {`${(userInfo?.get('ethAddress') ?? userAddress)?.slice(
                        0,
                        6,
                      )}...${(
                        userInfo?.get('ethAddress') ?? userAddress
                      )?.slice(-4)}` || ''}
                    </div>
                    <div className="ml-[14px]">
                      Joined{' '}
                      {dayjs(userInfo?.createdAt)
                        .locale('en')
                        .format('MMM YYYY')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-[117px] w-[500px] py-[15px] px-[10px] text-[#828282] text-[14px]">
                <div>{userInfo?.attributes?.about}</div>
                <div className="flex mt-6">
                  {[
                    { title: 'works', value: ownedList?.length || 0 },
                    { title: 'owners', value: owners },
                    { title: 'total price', value: '100.11K' },
                  ].map((x, i) => (
                    <div key={i} className="ml-14 first:ml-0">
                      <div className="text-[22px] leading-[26px] text-[#000] font-numberText">
                        {x.value}
                      </div>
                      <div className="text-[16px]">{x.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* User NFT Content */}
        <div className="bg-[rgba(255,255,255,0.44)] -translate-y-[50px] pb-20">
          <div className="mx-auto max-w-7xl md:px-16 sm:px-4">
            <div className="h-[50px] flex items-center justify-between">
              <div className="flex h-full gap-16">
                {['Owned', 'on Sale', 'Sold'].map((x, i) => (
                  <div
                    key={i}
                    className={`flex items-center cursor-pointer ${
                      activeTab !== i
                        ? 'border-none'
                        : 'border-b-2 border-[#1E94FC] font-bold font-boldText'
                    }`}
                    onClick={() => onTabChange(i)}
                  >
                    {x}
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-between">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="w-6 h-6"
                >
                  <g id="screen">
                    <circle
                      id="Ellipse 15"
                      cx="6"
                      cy="7"
                      r="3"
                      stroke="#4F4F4F"
                      strokeWidth="2"
                    />
                    <circle
                      id="Ellipse 16"
                      cx="17"
                      cy="17"
                      r="3"
                      stroke="#4F4F4F"
                      strokeWidth="2"
                    />
                    <path
                      id="Vector 16"
                      d="M12 7C12.4 7 18.1667 7 21 7"
                      stroke="#4F4F4F"
                      strokeWidth="2"
                    />
                    <path
                      id="Vector 17"
                      d="M2 17C2.4 17 8.16667 17 11 17"
                      stroke="#4F4F4F"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
                <div className="w-[2px] h-3 mx-[14px] border-l border-[#E0E0E0]"></div>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-[62px] mx-auto grid gap-[30px] max-w-7xl grid-cols-4 md:max-w-[951px] md:grid-cols-3 xmd:max-w-[684px] xmd:grid-cols-2 sm:max-w-[297px] sm:grid-cols-1">
              {
                [
                  ownedList?.map((x, i) => (
                    <NFTCard
                      key={i}
                      NFTInfo={x}
                      width="297px"
                      height="352px"
                      imgHeight="186px"
                      showToSell
                      onToSell={onToSell(x.token_id, x.token_address)}
                    />
                  )),
                  saleList?.map((x, i) => [
                    <SaleNFTCard
                      key={i}
                      NFTInfo={x}
                      width="297px"
                      height="352px"
                      imgHeight="186px"
                      onToSell={onToSell(x.token_id, x.token_address)}
                    />,
                    // <NFTAuthorCard
                    //   key={i}
                    //   NFTAuthorInfo={x}
                    //   width="297px"
                    //   height="352px"
                    //   imgHeight="186px"
                    // />,
                  ]),
                  soldList?.map((x, i) => (
                    <SaleNFTCard
                      key={i}
                      NFTInfo={x}
                      width="297px"
                      height="352px"
                      imgHeight="186px"
                      onToSell={onToSell(x.token_id, x.token_address)}
                    />
                  )),
                ][activeTab]
              }
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-[#F3F7FA]" />
      <Popup
        visible={sellPopupVisible}
        onOk={() => {
          document.body.style.overflow = 'auto'
          setSellPopupVisible(false)
        }}
        onCancel={() => {
          document.body.style.overflow = 'auto'
          setSellPopupVisible(false)
        }}
      >
        <div className="text-[34px] leading-[52px] font-boldText font-bold">
          Set sales price
        </div>
        <form
          onSubmit={sellHandleSUbmit(onToSellSubmit)}
          className="mt-8 w-full"
        >
          <div className="relative">
            <input
              {...sellRegister('sellPrice', { required: true, min: 0 })}
              type="text"
              className={`w-full h-[62px] py-[18px] pl-4 pr-20 font-numberText placeholder:text-[16px] placeholder:leading-[22px] overflow-hidden outline-none bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] hover:shadow-[0_6px_18px_4px_#C0E2FF] focus:shadow-[0_6px_18px_4px_#C0E2FF] rounded border border-solid ${
                sellErrors.sellPrice
                  ? 'border-red-500 focus:shadow-none hover:shadow-none'
                  : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
              }`}
              placeholder="Your selling price"
            />
            <div className="flex flex-row absolute top-1/2 right-4 -translate-y-1/2">
              <span>ETH</span>
              <img src={ethIcon} alt="ethIcon" className="w-6 h-6 ml-1" />
            </div>

            {sellWatch('sellPrice') && (
              <CleanValueIcon
                onClick={() => sellResetField('sellPrice')}
                className="right-14"
              />
            )}
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="w-[116px] h-[50px] bg-[#1E94FC] rounded-[4px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] text-white leading-[22px]"
            >
              Complete
            </button>
            <button
              type="button"
              className="w-[96px] h-[50px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded border border-[#BDBDBD] text-[#BDBDBD] ml-4"
              onClick={() => {
                sellReset()
                document.body.style.overflow = 'auto'
                setSellPopupVisible(false)
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Popup>
    </>
  )
}

export default NFTCreatorsDetails
